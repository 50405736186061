import React from 'react'

export default function BackGround() {
    return (
        <div className='backGround'>
            <div className='backGroundCircle1'></div>
            <div className='backGroundCircle2'></div>
            <div className='backGroundCircle3'></div>
        </div>
    )
}
